import React from "react";
import { Link } from "react-router-dom";
import pageLinks from "../../utils/links";
import { scrollToTop } from "../../utils/utils";

export default function PackageCard({ id, title, description, price }) {
	let pricePeriod = "/ monthly";
	if (price === 100) {
		pricePeriod = "/ for life";
	} else if (price === 200) {
		pricePeriod = "/ for 1 month";
	}else {
		pricePeriod = "/ for 2 months";
	}
	return (
		<div className="col-lg-4 col-md-4 col-12 mt-4 mt-md-0">
			<div className="box">
				<h3>{title}</h3>
				<h4>
					<sup>$. </sup>
					{price}
					<span> {pricePeriod} </span>
				</h4>
				<p
					dangerouslySetInnerHTML={{
						__html: description.slice(0, 300),
					}}
				/>
				<div className="btn-wrap">
					<Link
						to={pageLinks.products.url}
						className="btn-buy"
						onClick={() => scrollToTop()}
					>
						View {title.includes("unding") ? "Details": "Courses"}
					</Link>
				</div>
			</div>
		</div>
	);
}
