import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(true);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav>
          <NavIcon to="#">
            <FaIcons.FaBars onClick={showSidebar} />
          </NavIcon>
        </Nav>
        {/* <nav id="navbar"
						className="navbar-mobile">
          <ul></ul>
          <ul></ul>
        </nav> */}
        <SidebarNav sidebar={sidebar}>
          <SidebarWrap>
            <NavIcon to="#">
              <AiIcons.AiOutlineClose onClick={showSidebar} />
            </NavIcon>
            {SidebarData.map((item, index) => {
              return (
                <SubMenu item={item} key={index} showSidebar={showSidebar} />
              );
            })}
            <PortalDiv>
              <button>
                <a
                  href="https://sso.teachable.com/secure/390752/identity/login"
                  target="_blank"
                  rel="noreferrer"
                >
                  Student Portal
                </a>
              </button>
            </PortalDiv>
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;

const Nav = styled.div`
  background: transparent;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: #0b1632;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const PortalDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 2em;
  padding-top: 1.5em;
  color: white;
  > button {
    height: 2.5em;
    padding: 0.1em 1em;
    border: none;
    border-radius: 50px;
    background-color: #f6b024;
    color: white;

    > a {
      color: white;
    }
  }
`;
