import React from "react";
import * as RiIcons from "react-icons/ri";
  
export const SidebarData = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Forex Courses",
    path: "/products",
  },
  {
        title: "Podcasts",
        path: "/podcasts/",
      },
    {
    title: "Articles",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  
    subNav: [
      {
        title: "Educational",
        path: "/articles/educational/",
        cName: "sub-nav",
        category: "E"
      },
      {
        title: "Psychology",
        path: "/articles/psychology/",
        cName: "sub-nav",
        category: "P"
      },
      {
        title: "Crypto",
        path: "/articles/crypto/",
        cName: "sub-nav",
        category: "C"
      },
    ],
    },
    {
    title: "Free Forex School",
    path: "/school/",
  },
    {
    title: "About",
    path: "/about/",
  },
  
  {
    title: "Contact",
    path: "/contact/",
  },
];