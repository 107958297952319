import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { GetDataLayerStateValue } from "../../utils/context";

  
const SubMenu = ({ item, showSidebar }) => {
  const [subnav, setSubnav] = useState(false);
  const [, dispatch] = GetDataLayerStateValue();
  
    const showSubnav = () => setSubnav(!subnav);

    const articlesUrlClickHandler = (category) => {
      dispatch({
        type: "SET_ARTICLES_URL",
        payload: `api/v1/content/articles/${category}/`,
      });
      // sroll to the top of the page in the process
      window.scrollTo(0, 0);
    };
    
    return (
    <>
      <SidebarLink to={item.path} 
      onClick={item.subNav ? showSubnav : showSidebar}>
        <div>
          {item.icon}
          <SidebarLabel>{item.title}</SidebarLabel>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((item, index) => {
          return (
            <DropdownLink to={item.path} key={index} onClick={() => { showSidebar(); showSubnav(); }}>
              {item.icon}
              <SidebarLabel onClick={() => {articlesUrlClickHandler(item.category)}}>{item.title}</SidebarLabel>
            </DropdownLink>
          );
        })}
    </>
  );
};

export default SubMenu;

const SidebarLink = styled(Link)`
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;
  
  &:hover {
    background: #032e54;
    border-left: 4px solid #f6b024;
    cursor: pointer;
  }
`;
  
const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const DropdownLink = styled(Link)`
  background: #0c5494;
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  
  &:hover {
    cursor: pointer;
  }
`;
