/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import logo from "../../img/logo.png";
import { Link, NavLink } from "react-router-dom";
import pageLinks from "../../utils/links";
import { GetDataLayerStateValue } from "../../utils/context";
import { isUserAuthenticated } from "../../utils/utils";
import Sidebar from '../SideBar/Sidebar';

export default function Navbar() {
	const [, dispatch] = GetDataLayerStateValue();
	const [isNavOpen, setIsNavOpen] = useState(false);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [isArticleDropdownOpen, setIsArticleDropdownOpen] = useState(false);
	const [portalRedirect, setPortalRedirect] = useState(false);
	const isAuthenticated = isUserAuthenticated();

	const closeSmallScreenNav = () => {
		setIsNavOpen(false);
		setIsDropdownOpen(false);
		setIsArticleDropdownOpen(false);
		// sroll to the top of the page in the process
		window.scrollTo(0, 0);
	};

	const articlesUrlClickHandler = (category) => {
		dispatch({
			type: "SET_ARTICLES_URL",
			payload: `api/v1/content/articles/${category}/`,
		});
		setIsNavOpen(false);
		setIsDropdownOpen(false);
		setIsArticleDropdownOpen(false);
		// sroll to the top of the page in the process
		window.scrollTo(0, 0);
	};

	const logoutUser = () => {
		localStorage.removeItem("access_token");
		localStorage.removeItem("refresh_token");
		localStorage.removeItem("token_expiry");
		window.location.href = pageLinks.login.url;
	};

	return (
		<>
			<div
				id="topbar"
				className="fixed-top d-flex align-items-center topbar-inner-pages"
			>
				<div className="container d-flex align-items-center justify-content-center justify-content-md-between">
					<div className="contact-info d-flex align-items-center">
						<i className="bi bi-envelope-fill"></i>
						<a href="mailto:contact@example.com">info@financialhubfx.com</a>
						<i className="bi bi-phone-fill phone-icon"></i> +254702803735 /
						+254716001194
					</div>
					{isAuthenticated ? (
						<div className="cta d-none d-md-block">
							<a href="#" onClick={logoutUser}>
								Log Out
							</a>
						</div>
					) : (
						<div className="cta d-none d-md-block">
							<Link
								to={pageLinks.login.url}
								className="scrollto"
								onClick={() => closeSmallScreenNav()}
							>
								{pageLinks.login.name}
							</Link>
						</div>
					)}
				</div>
			</div>

			<header
				id="header"
				className="fixed-top d-flex align-items-center header-inner-pages"
			>
				<div className="container d-flex align-items-center justify-content-between">
					<Link to="/" className="logo">
						<img src={logo} alt="logo" className="img-fluid" />
					</Link>

					{isNavOpen ? (<Sidebar />) : (
						<nav
						id="navbar"
						className="navbar"
					>
						<ul>
							<li>
								<NavLink
									exact
									activeClassName="active"
									to={pageLinks.home.url}
									className="nav-link scrollto"
									onClick={() => closeSmallScreenNav()}
								>
									{pageLinks.home.name}
								</NavLink>
							</li>
							<li>
								<NavLink
									exact
									activeClassName="active"
									to={pageLinks.products.url}
									className="nav-link scrollto"
									onClick={() => closeSmallScreenNav()}
								>
									{pageLinks.products.name}
								</NavLink>
							</li>

							<li>
								<NavLink
									activeClassName="active"
									className="nav-link scrollto"
									to={pageLinks.podcasts.url}
									onClick={() => closeSmallScreenNav()}
								>
									{pageLinks.podcasts.name}
								</NavLink>
							</li>
							<li className="dropdown">
								<a
									href="#"
									onClick={() =>
										setIsArticleDropdownOpen(!isArticleDropdownOpen)
									}
								>
									<span>Articles</span> <i className="bi bi-chevron-down"></i>
								</a>
								<ul className={isArticleDropdownOpen ? "dropdown-active" : ""}>
									<li>
										<NavLink
											activeClassName="active"
											to={pageLinks.educationalArticles.url}
											onClick={() => articlesUrlClickHandler("E")}
										>
											{pageLinks.educationalArticles.name}
										</NavLink>
									</li>
									<li>
										<NavLink
											activeClassName="active"
											to={pageLinks.psychologyArticles.url}
											onClick={() => articlesUrlClickHandler("P")}
										>
											{pageLinks.psychologyArticles.name}
										</NavLink>
									</li>
									<li>
										<NavLink
											activeClassName="active"
											to={pageLinks.cryptoArticles.url}
											onClick={() => articlesUrlClickHandler("C")}
										>
											{pageLinks.cryptoArticles.name}
										</NavLink>
									</li>
								</ul>
							</li>

							<li>
								<NavLink
									activeClassName="active"
									className="nav-link"
									to={pageLinks.school.url}
									onClick={() => closeSmallScreenNav()}
								>
									{pageLinks.school.name}
								</NavLink>
							</li>
							<li>
								<NavLink
									activeClassName="active"
									className="nav-link scrollto"
									to={pageLinks.about.url}
									onClick={() => closeSmallScreenNav()}
								>
									{pageLinks.about.name}
								</NavLink>
							</li>
							<li>
								<NavLink
									activeClassName="active"
									className="nav-link scrollto"
									to={pageLinks.contact.url}
									onClick={() => closeSmallScreenNav()}
								>
									{pageLinks.contact.name}
								</NavLink>
							</li>
							<li>
								<a href="https://sso.teachable.com/secure/390752/identity/login" target="_blank" rel="noreferrer">Student Portal</a>
							</li>
							<li>
								{isAuthenticated ? (
									<a
										href="#"
										className="nav-link scrollto d-block d-sm-none"
										onClick={() => {logoutUser(); closeSmallScreenNav()}}
									>
										Log Out
									</a>
								) : (
									<NavLink
										activeClassName="active"
										className="nav-link scrollto d-block d-sm-none"
										to={pageLinks.login.url}
										onClick={() => closeSmallScreenNav()}
									>
										{pageLinks.login.name}
									</NavLink>
								)}
							</li>
						</ul>
						<i
							className={
								isNavOpen
									? "bi mobile-nav-toggle bi-x"
									: "bi mobile-nav-toggle bi-list"
							}
							onClick={() => setIsNavOpen(!isNavOpen)}
						></i>
					</nav>
					)}
				</div>
			</header>
		</>
	);
}
