import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import pageLinks from "../../utils/links";
import { scrollToTop } from "../../utils/utils";
import ClockImage from "../../img/clock.png";
import ModulesImage from "../../img/cube.png";

export default function CoursesCard({ id, image, title, description, modules, hours }) {
  return (
    <Link to={pageLinks.courses.url + id + "/"} onClick={() => scrollToTop()}>
      <MainCardDiv>
        <img src={image} alt={title}></img>
        <div>
          <h4>{title}</h4>
          <div>
            <p>
              <img src={ModulesImage} alt="modules"></img>
              <span>Modules: {modules}</span>
            </p><p>
              <img src={ClockImage} alt="clock"></img>
              <span>Hours: {hours}</span>
            </p>
          </div>
		  <a href={pageLinks.courses.url + id + "/"}><p>&#8594;</p></a>
        </div>
      </MainCardDiv>
    </Link>
  );
}

const MainCardDiv = styled.div`
  height: auto;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 2rem;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  > img {
    width: 100%;
    height: auto;
    border-radius: 5px 5px 0 0;
  }
  > div {
    padding: 1em;
    > h4 {
      font-size: 1.3rem;
      font-weight: 450;
      margin-bottom: 1rem;
    }
    > div {
		width: 80%;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;

      > p {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        > span {
          font-size: 0.8em;
          font-weight: 400;
		  color: black;
		  margin-left: .2em;
        }
        > img {
          height: 15px;
          width: 15px;
        }
      }
    }
	> a {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: flex-end;
		height: 1.5em;
		width: 95%;
		> p { 
		font-size: 2em;
		}
	}
  }
  :hover {
	border-radius: 9px;
	box-shadow:  22px 22px 36px #d3d3d3,
				 -22px -22px 36px #ededed;
	transition: all 0.6s ease-in-out;
  }


  @media (max-width: 768px) {
	  margin-bottom: .5em;

	  > div {
		  > div {
			  width: 90%;
		  }
	  }
  }
`;
