import React, { useCallback, useEffect } from "react";
import CoursesCard from "../components/Cards/CoursesCard";
import Loading from "../components/Loading/Loading";
import { axiosInstance } from "../utils/axios";
import { GetDataLayerStateValue } from "../utils/context";

export default function CoursesPage() {
  const [{ isLoading, courses }, dispatch] = GetDataLayerStateValue();

  const getCourses = useCallback(() => {
    dispatch({ type: "SET_IS_LOADING", payload: true });
    axiosInstance
      .get("/api/v1/product/courses/")
      .then((response) => {
        const data = response.data;
        dispatch({
          type: "SET_COURSES",
          payload: data.results,
          nextUrl: data.next,
          previousUrl: data.previous,
        });
        dispatch({ type: "SET_IS_LOADING", payload: false });
      })
      .catch((error) => {
        console.log("Error during getting courses >>>>", error.response);
      });
  }, [dispatch]);

  useEffect(() => {
    getCourses();
  }, [getCourses]);

  if (isLoading || courses.length === 0) {
    return <Loading />;
  }
  return (
    <main id="main">
      {/* <Breadcrumb page="Courses" title="Courses" /> */}
      <section id="team" className="team section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Courses</h2>
            <p>
              We offer the best courses to helop you kickstart or improve in
              your trading journey
            </p>
          </div>

          <div className="courses-cards-div">
            <div className="col-md-6 mb-2 mt-4" key={4}>
              <CoursesCard
                title={"Forex E-learning Course"}
                id={1}
                image={courses[0].image}
                // description={description}
                modules={8}
                hours={"10+"}
              />
            </div>
            <div className="col-md-6 mb-4 mt-4" key={4}>
              <CoursesCard
                title={"Forex Master class course"}
                id={3}
                image={courses[0].image}
                // description={description}
                modules={8}
                hours={16}
              />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
