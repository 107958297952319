import React from "react";
import TeamCard from "./TeamCard";
import TeamMemberImg from "../../img/team/Ken.jpeg";
import TeamMemberImgTwo from "../../img/team/Caleb.jpeg";
import TeamMemberImgThree from "../../img/team/Tarrus.jpeg";
import TeamMemberImgFour from "../../img/team/team-4.jpg";

const teamMembers = [
  {
    name: "Kenneth M",
    title: "Fund Manager/Founder",
    quote: `Ken is a founder, fund manager, and trader at Financial hub. He has a background in 
    project management from the professional google courses. 
    At Financial hub, he is focused on trading currencies, commodities, and indices mainly. 
    His responsibilities include; mentoring students, managing the fund, and running the 
    company. Ken is an avid reader who is passionate about learning and building 
    companies. 
    His philosophy revolves around creating opportunities in the African market by 
    developing professional forex traders. He has authored a book titled "A Template For Understanding The Forex Market."`,
    image: TeamMemberImg,
    linkedIn: "https://www.linkedin.com/in/Ken-Munene",
  },
  {
    name: "Caleb Sam",
    title: "Director/Founder",
    quote:
      "Caleb is a Founder, Mentor, Trader and a Creative Content Creator at Financial Hub.His preferable ways of trading involves deep and long term technical analysis, which he has perfected over the years to create an edge for himself.Alongside his co-founders, he also runs the company operations on a day to day basis, with the aim of constantly improving their products so that their clients can get top of the class value.He believes in Ray Dalio’s Philosophy on company culture. His ideas on the culture of radical truthfulness and transparency in an idea meritocratic way, which aims to find the best solutions while tackling problems",
    image: TeamMemberImgTwo,
    linkedIn: "https://www.linkedin.com/in/caleb-sam-05187012b",
  },
  {
    name: "Tarrus Lawi",
    title: "Risk Manager/Founder",
    quote:
      "Tarrus Lawi is a co-founder, Trader, mentor and Head Risk Manager at Financial Hub.Lawi is responsible for day to day business activities, coming up with market tested risk management frameworks for the Financial Hub trading fund and mentoring traders on the best way to manage the different kinds of risk in Trading.Lawi is passionate about entrepreneurship, solving problems, creating value and giving back to community.",
    image: TeamMemberImgThree,
    linkedIn: " https://www.linkedin.com/in/tarrus-lawi",
  },
  // {
  //   name: "Harman Kibue",
  //   title: "External Software Consultant",
  //   quote:
  //     "Harman Kibue is a seasoned software engineer with background in Electrical and electronics engineering(computer option) from The  Technical University Of Kenya.Harman has worked at several tech companies and currently an Andelan.At Financial Hub he is involved in overseeing and leading the design, implementation, automation and scaling already existing financial software solutions.",
  //   image: TeamMemberImgFour,
  //   linkedIn: "https://www.linkedin.com/in/harmann-kibue",
  // },
];

export default function TeamList() {
  return (
    <div className="row">
      {teamMembers.map((member) => {
        const { name, title, quote, image, linkedIn } = member;
        return (
          <TeamCard
            key={name}
            name={name}
            title={title}
            quote={quote}
            image={image}
            linkedIn={linkedIn}
          />
        );
      })}
    </div>
  );
}
