import React from "react";
import Breadcrumb from "../components/Hero/Breadcrumb";
import TeamList from "../components/Team/TeamList";

export default function AboutPage() {
  return (
    <>
      <Breadcrumb page="About" title="About Us" />
      <section id="about" className="about">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>About Us</h2>
          </div>

          <div className="row content">
            <div className="col-md-12 col-12 pt-4 pt-lg-0">
              <p>
                Financial hub is a company that is focused on training, funding,
                and mentoring <a target="_blank" href="https://www.ig.com/en/forex/how-to-trade-forex" rel="noreferrer">forex traders</a> . The company was started in 2018 by
                three young, passionate forex traders out of a two-bedroom
                apartment in Nairobi.
              </p>
              <p>
                We are on a mission to revolutionize forex trading in Africa. We
                believe an opportunity exists in the financial markets, but
                taking the proper steps is critical for success. Our mission is
                to change lives by developing consistently profitable forex
                traders. We believe we can create jobs, wealth, and
                opportunities in the financial markets. Over the last two years,
                we have trained and mentored over a hundred students in this
                business. Join us on our mission to improve livelihoods in
                Africa.
              </p>
              <button className="student-portal-button"><a href="/faqs">See our FAQs</a></button>

            </div>
          </div>
        </div>
      </section>

      <section id="team" className="team section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Our Team</h2>
          </div>
          <TeamList />ss
        </div>
      </section>
    </>
  );
}
