import React from "react";
import Carousel from "react-bootstrap/Carousel";
import sliderImage from "../../img/fhub/fhub-home.webp";
import sliderImage2 from "../../img/fhub/forexHero2.webp";
// import sliderImage3 from "../../img/fhub/pepperstone.webp";

export default function Hero() {
  return (
    <section
      id="hero"
      className="d-flex justify-content-center align-items-center"
    >
      <Carousel>
        <Carousel.Item interval={7000}>
          <img
            className="d-block w-100 responsive"
            src={sliderImage}
            alt="First slide"
          />
          <Carousel.Caption>
            <div className="carousel-container">
              <h1 className="hero-heading">FINANCIAL HUB FOREX ACADEMY</h1>
              <p className="mt-3 ">We train and mentor forex traders</p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={7000}>
          <img
            className="d-block w-100 responsive"
            src={sliderImage2}
            alt="Second slide"
          />
          <Carousel.Caption>
            <div className="carousel-container">
              <h1 className="hero-heading">FINANCIAL HUB FOREX ACADEMY</h1>
              <p className="mt-3 ">Kickstart your forex career with our courses</p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        {/* <Carousel.Item>
          <a href="https://trk.pepperstonepartners.com/aff_c?offer_id=367&aff_id=29178" target="_blank" rel="noreferrer">
            <img
            className="d-block w-100 responsive"
            src={sliderImage3}
            alt="Third slide"
            style={{filter: "none"}}
          /></a>
          
          <Carousel.Caption>
            <div className="carousel-container">
            <a href="https://trk.pepperstonepartners.com/aff_c?offer_id=367&aff_id=29178" target="_blank" rel="noreferrer" style={{marginTop: "9em", height: "10em", width: "10em", opacity: "0"}}>
              Switch to Pepperstone
            </a>
            </div>
          </Carousel.Caption>
        </Carousel.Item> */}
      </Carousel>
    </section>
  );
}
