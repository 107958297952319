import React, { useCallback, useEffect, useState } from "react";
import { axiosInstance } from "../../utils/axios";
import { GetDataLayerStateValue } from "../../utils/context";
import Loading from "../Loading/Loading";
import PackageCard from "./PackageCard";

export default function PackagesList() {
	const [{ isLoading }, dispatch] = GetDataLayerStateValue();
	const [packages, setPackages] = useState([]);

	const getPackages = useCallback(() => {
		dispatch({ type: "SET_IS_LOADING", payload: true });
		dispatch({ type: "SET_IS_ERROR", payload: false });

		axiosInstance
			.get("/api/v1/product/packages/")
			.then((response) => {
				const data = response.data;
				setPackages(data.results);
				dispatch({ type: "SET_IS_LOADING", payload: false });
			})
			.catch((error) => {
				console.log("ERROR WHEN FETCHING PACKAGES...", error.response);
			});
	}, [dispatch]);

	useEffect(() => {
		getPackages();
	}, [getPackages]);

	if (isLoading) {
		return <Loading />;
	}
	return (
		<section id="pricing" className="pricing mt-5">
			<div className="container" data-aos="fade-up">
				<div className="section-title">
					<h2>Our Courses Packages</h2>
					<p>
						The three programs listed below have been created and curated by our team of experts.Learn how to trade like a pro! 
					</p>
				</div>

				<div className="row">
					{packages.filter(item => item.id !== 4 ).map((packge) => {
						const { id, title, description, price } = packge;
						return (
							<PackageCard
								key={id}
								id={id}
								title={title}
								description={description}
								price={price}
							/>
						);
					})}
				</div>
			</div>
		</section>
	);
}
