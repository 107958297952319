import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import client1 from "../../img/clients/client-1.webp";
import client2 from "../../img/clients/client-2.webp";
// import client3 from "../../img/clients/client-3.webp";
import client4 from "../../img/clients/client-1.webp";
import client5 from "../../img/clients/client-2.webp";
// import client6 from "../../img/clients/client-3.webp";
export default class Partners extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 3,
      initialSlide: 0,
      autoplay: true,
      speed: 5000,
      autoplaySpeed: 2000,
      pauseOnHover: true,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
      ],
    };
    return (
      <div className="clients">
        <Slider {...settings}>
          <div className="swiper-slide">
            <img src={client1} alt="client 1" />
          </div>
          <div className="swiper-slide">
            <img src={client2} alt="client 1" />
          </div>
          {/*  Broker */}
          <div className="swiper-slide">
              <img src="https://ads.pipaffiliates.com/i/640?c=767892" width="56" height="43" referrerpolicy="no-referrer-when-downgrade"/>
          </div>
          <div className="swiper-slide">
            <img src={client4} alt="client 1" />
          </div>
          <div className="swiper-slide">
            <img src={client2} alt="client 1" />
          </div>
                    {/*  Broker */}
          <div className="swiper-slide">
              <img src="https://ads.pipaffiliates.com/i/640?c=767892" width="56" height="43" referrerpolicy="no-referrer-when-downgrade"/>
          </div>
          <div className="swiper-slide">
            <img src={client4} alt="client 1" />
          </div>
          <div className="swiper-slide">
            <img src={client5} alt="client 1" />
          </div>
          <div className="swiper-slide">
              <img src="https://ads.pipaffiliates.com/i/640?c=767892" width="56" height="43" referrerpolicy="no-referrer-when-downgrade"/>
          </div>
        </Slider>
      </div>
    );
  }
}
